<template>
  <AppContainer id="thrillers-edit" class="my-5">
    <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col cols md="12" class="my-3">
          <h4 class="lead text-center">Edit Thriller</h4>
        </b-col>
        <b-col cols md="12" class="ml-auto mr-auto">
          <b-row>
            <b-col cols md="12">
              <b-form-group label-for="title-input">
                <b-form-input
                  v-model.trim="movie.title"
                  id="title-input"
                  required
                  placeholder="Movie title"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="12">
              <b-form-group label-for="rating-input">
                <b-form-input
                  v-model.trim="movie.rating"
                  id="rating-input"
                  required
                  placeholder="Movie rating"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="12">
              <b-form-group label-for="thriller-input">
                <b-form-input
                  v-model.trim="movie.thriller"
                  id="thriller-input"
                  required
                  placeholder="Youtube thriller"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="12">
              <b-form-group label-for="description-input">
                <b-form-textarea
                  v-model.trim="movie.description"
                  id="description-input"
                  required
                  placeholder="Movie Description"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col cols md="12">
              <b-form-group label-for="thriller-input">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="movie.status"
                  name="checkbox-1"
                  value="accept"
                  unchecked-value="decline"
                >I want to upload a new image</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col v-if="movie.status == 'accept'" cols md="12" class="mb-1">
              <b-form-group id="input-group-1" label-for="picture-input">
                <b-form-file accept="image/png, image/jpeg" @change="handleFileChange($event)"></b-form-file>
              </b-form-group>
            </b-col>

            <b-col cols md="12" class="mb-1">
              <section>
                <ul v-if="errors.length > 0">
                  <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
              </section>
            </b-col>

            <b-col cols md="12" class="mb-1" v-show="showProgress">
              <h4 class="lead">Saving...</h4>
              <b-progress :value="progress" max="100" show-progress animated></b-progress>
            </b-col>
          </b-row>

          <b-col cols md="12" class="my-1">
            <b-button type="submit" variant="primary" :disabled="!validate">Update Thriller</b-button>
            <b-button type="button" variant="link" to="/thrillers">Back to thrillers</b-button>
          </b-col>
        </b-col>
      </b-row>
    </b-form>
  </AppContainer>
</template>

<script>
const AppContainer = () => import("@/core/Components/App/AppContainer.vue");
import { api } from "../../resource";
import axios from "axios";
export default {
  name: "thrillers-edit",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      results: null,
      errors: [],
      file: null,
      filesSelected: 0,
      cloudName: 'brandscinemas',
      preset: "brandscinemas",
      tags: "browser-upload",
      progress: 0,
      showProgress: false,
      fileContents: null,
      formData: null,
      movie: {
        thriller: "",
        imgurl: "",
        rating: "",
        title: "",
        description: ""
      }
    };
  },
  created() {
    api
      .create("thrillers/show", { ...this.movie, thriller_id: this.id })
      .then(res => {
        res.data.status = "decline";
        this.movie = res.data;
      });
  },
  methods: {
    onUpdate() {
      this.movie.status == "decline" ? this.uploadOld() : this.upload();
    },
    // function to handle file info obtained from local
    // file system and set the file state
    handleFileChange: function(event) {
      console.log("handlefilechange", event.target.files);
      //returns an array of files even though multiple not used
      this.file = event.target.files[0];
      this.filesSelected = event.target.files.length;
    },
    prepareFormData: function() {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("tags", this.tags); // Optional - add tag for image admin in Cloudinary
      this.formData.append("file", this.fileContents);
    },
    // function to handle form submit
    upload() {
      //no need to look at selected files if there is no cloudname or preset
      if (this.preset.length < 1 || this.cloudName.length < 1) {
        this.errors.push("You must enter a cloud name and preset to upload");
        return;
      }
      // clear errors
      else {
        this.errors = [];
      }
      console.log("upload", this.file.name);
      let reader = new FileReader();
      // attach listener to be called when data from file
      reader.addEventListener(
        "load",
        function() {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function(progressEvent) {
              console.log("progress", progressEvent);
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
              console.log(this.progress);
              //bind "this" to access vue state during callback
            }.bind(this)
          };
          //show progress bar at beginning of post
          this.showProgress = true;
          axios(requestObj)
            .then(response => {
              this.results = response.data;
              this.movie.imgurl = this.results.url;
              console.log(response)
              api
                .create("thrillers/update", this.movie)
                .then(() => {
                  this.$swal(
                    "Success",
                    "Thriller successfully update!",
                    "success"
                  );
                })
                .catch(e => {
                  this.$swal(e.body, e.body.data, "error");
                });
            })
            .catch(error => {
              this.errors.push(error);
              console.log(this.error);
            })
            .finally(() => {
              setTimeout(
                function() {
                  this.showProgress = false;
                }.bind(this),
                1000
              );
            });
        }.bind(this),
        false
      );
      // call for file read if there is a file
      if (this.file && this.file.name) {
        reader.readAsDataURL(this.file);
      }
    },
    uploadOld() {
      api
        .create("thrillers/update", this.movie)
        .then(() => {
          this.$swal("Success", "Thriller successfully updated!", "success");
        })
        .catch(e => {
          this.$swal(e.body.status_message, e.body.data, "error");
        });
    }
  },
  components: {
    AppContainer
  },
  computed: {
    validate() {
      return this.movie.thriller.length >= 3 &&
        this.movie.description.length >= 3 &&
        this.movie.rating.length >= 1 &&
        this.movie.title.length >= 3;
    }
  }
};
</script>
